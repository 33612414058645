import { useEffect } from "react";
import {
  Navigate,
  Outlet,
  useOutletContext,
  useParams,
} from "react-router-dom";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { CompanyContextType } from "../_layout";
import { useProperties } from "../../../api/queries";

export default function PropertyLayout() {
  const { company } = useOutletContext<CompanyContextType>();
  const { propertySlug } = useParams();
  const [_, setTitle, resetTitle] = useDocumentTitle();
  const {
    data: properties,
    isLoading,
    isFetched,
    error,
  } = useProperties({ slug: propertySlug, company__slug: company?.slug });

  useEffect(() => {
    if (!company || !propertySlug) {
      return;
    }

    if (!properties && isFetched) {
      throw new Error("Property not found");
    }
  }, [properties, isFetched, company, propertySlug]);

  useEffect(() => {
    if (
      typeof company === "undefined" ||
      company === null ||
      typeof properties === "undefined"
    ) {
      resetTitle();
      return;
    }

    setTitle(`${properties[0]?.name} Tour Scheduling`);
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <Navigate to={`/${company?.slug}`} />;
  }

  return (
    company &&
    properties && (
      <div className="flex flex-col gap-6 items-center w-full">
        <img className="h-16" src={company?.logo} alt={company?.name} />
        <Outlet />
      </div>
    )
  );
}
