import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import { useEvent } from "../../../../api/queries";
import { useEffect, useMemo } from "react";
import { useScheduler } from "../../../../contexts/SchedulerContext";
import dayjs from "dayjs";
import { EventSubType } from "../../../../types/scheduler";
import { useQueryClient } from "@tanstack/react-query";

export default function EventLayout() {
  const { eventId, companySlug, propertySlug } = useParams();
  const queryClient = useQueryClient()
  const { data: event, isLoading, error } = useEvent(eventId || "");
  const { setTourType, setDate, setSelectedHour } = useScheduler();

  const hasContact = queryClient.getQueryData(["contact", event?.guests[0]]);

  const location = useLocation();
  const isCancelOrReschedule = useMemo(() => {
    const lastRoute = location.pathname.split("/").filter(Boolean).pop();
    return lastRoute === "cancel" || lastRoute === "reschedule";
  }, [location]);

  useEffect(() => {
    if (event) {
      setTourType(event.event_subtype || EventSubType.agendScheduled);
      setDate(new Date(event.start_datetime));

      const endDate = new Date(
        dayjs(event.start_datetime).add(30, "minutes").format()
      );
      setSelectedHour({
        emails: [],
        start_time: new Date(event.start_datetime),
        end_time: new Date(endDate),
      });
    }
  }, [event]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isCancelOrReschedule && (error || !hasContact)) {
    return <Navigate to={`/${companySlug}/${propertySlug}`} />;
  }

  return <Outlet />;
}
