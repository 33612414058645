import { NylasScheduling } from "@nylas/react";
import DatePicker from "./datePicker";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import {
  useTeamByCompanyProperty,
  useTeamConfiguration,
  useCompanies,
  useProperties,
  useFutureAvailability,
} from "../api/queries";
import { useScheduler } from "../contexts/SchedulerContext";
import {
  ApiAvailabilityTimeslot,
  AvailabilityTimeslot,
} from "../types/scheduler";
import { useEffect } from "react";

const NylasScheduler = () => {
  const { companySlug, propertySlug } = useParams();
  const { data: company } = useCompanies({ slug: companySlug });
  const { data: properties } = useProperties({
    slug: propertySlug,
    company__slug: companySlug,
  });

  const { data: teams } = useTeamByCompanyProperty(
    company?.id || "",
    properties ? properties[0].id : ""
  );
  const {
    data: configuration,
    isLoading,
    isFetched,
  } = useTeamConfiguration(teams ? teams[0]?.id || "" : "");
  const { data: futureAvailability } = useFutureAvailability(
    configuration?.id || "",
    configuration?.scheduler.available_days_in_future || 0
  );

  const {
    dayAvailability,
    date,
    setDate,
    setDayAvailability,
    setSelectedHour,
  } = useScheduler();

  const filterEventsByDay = (
    events: AvailabilityTimeslot[] | undefined,
    targetDate: string
  ) => {
    if (!events || events.length === 0) {
      return [];
    }
    return events.filter((event) => {
      const eventStartDay = dayjs(event.start_time).format("YYYY-MM-DD");
      const eventEndDay = dayjs(event.end_time).format("YYYY-MM-DD");
      const targetDay = dayjs(targetDate).format("YYYY-MM-DD");
      return eventStartDay === targetDay || eventEndDay === targetDay;
    });
  };

  useEffect(() => {
    if (!futureAvailability) {
      return;
    }

    if (date) {
      return
    }

    const firstAvailableDay = dayjs(
      futureAvailability?.data.time_slots[0].start_time * 1000
    );
    setDate(firstAvailableDay.toDate());

    if (dayAvailability.length > 0) {
      return;
    }

    setDayAvailability(
      futureAvailability.data.time_slots
        .filter((slot: ApiAvailabilityTimeslot) => {
          return dayjs(slot.start_time * 1000).isSame(firstAvailableDay, "day");
        })
        .map((slot: ApiAvailabilityTimeslot) => ({
          ...slot,
          start_time: new Date(slot.start_time * 1000),
          end_time: new Date(slot.end_time * 1000),
        }))
    );
  }, [futureAvailability, date]);

  return !isLoading && isFetched && date ? (
    <NylasScheduling
      configurationId={configuration?.id}
      mode="composable"
      className="w-full shadow-none"
      eventOverrides={{
        dateSelected: async (event, connector) => {
          setDate(dayjs(event.detail).toDate());
          const dayAvailability = filterEventsByDay(
            connector?.schedulerStore.get("availability"),
            event.detail
          );
          setDayAvailability(dayAvailability);
          setSelectedHour(null);
        },
      }}
      defaultSchedulerState={{
        selectedDate: date,
      }}
      nylasBranding={false}
    >
      <DatePicker />
    </NylasScheduling>
  ) : (
    <div>Loading...</div>
  );
};

export default NylasScheduler;
