export enum EventSubType {
  agendScheduled = 0,
  walkIn = 1,
  virtual = 2,
}

export interface IFormData {
  full_name: string;
  email: string;
  phone_number: string;
}

export interface AvailabilityTimeslot {
  emails: string[];
  start_time: Date;
  end_time: Date;
}

export interface ApiAvailabilityTimeslot {
  emails: string[];
  start_time: number;
  end_time: number;
}