import { ClockIcon } from "@heroicons/react/24/outline";
import Switch from "./switch";
import { useScheduler } from "../contexts/SchedulerContext";
import { EventSubType } from "../types/scheduler";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import TimeScheduledDisplay from "./timeScheduledDisplay";
import NylasScheduler from "./nylasScheduler";
import { useEvent } from "../api/queries";
import HourBubble from "./hourBubble";

const TimePicker = ({ toNextStep }: { toNextStep: () => void }) => {
  const { eventId } = useParams();
  const { data: event } = useEvent(eventId || "");

  const { setTourType, dayAvailability } = useScheduler();

  const handleOnSwitch = (state: boolean) => {
    setTourType(state ? EventSubType.agendScheduled : EventSubType.virtual);
  };

  const title = useMemo(() => {
    if (event) {
      return "Reschedule your tour";
    } else {
      return "Schedule your tour";
    }
  }, [event]);

  return (
    <>
      <div className="flex flex-col gap-6 items-center my-4 w-full">
        <h1 className="text-[32px] font-medium text-center tracking-[0.3px]">
          {title}
        </h1>

        {event && (
          <>
            <p>You are currently scheduled for:</p>
            <TimeScheduledDisplay />
          </>
        )}

        <Switch onSwitch={handleOnSwitch} />
        <p className="text-center">
          We’ll give you a personalized tour of available apartments, our
          community and amenities.
        </p>
      </div>

      <div className="flex flex-col items-center">
        <h3 className="font-medium text-[20px] text-center tracking-[0.3px]">
          What day & time are best for you?
        </h3>

        <div className="flex gap-3">
          <ClockIcon color="#94A3B8" height={24} width={24} />
          <span className="font-medium text-secondary-text">
            20 minute tour
          </span>
        </div>
      </div>

      <NylasScheduler />
      <div className="flex gap-2 flex-wrap mt-8 w-full sm:justify-center">
        {dayAvailability.map((availability) => (
          <HourBubble
            key={availability.start_time.toISOString()}
            availability={availability}
            onSelect={toNextStep}
          />
        ))}
      </div>
    </>
  );
};

export default TimePicker;
