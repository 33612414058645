import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { formatDateWithTimeRange } from "../utils";
import { useScheduler } from "../contexts/SchedulerContext";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const TimeScheduledDisplay = ({
  hasGoBackButton,
  additionalClasses,
  onBack,
}: {
  hasGoBackButton?: boolean;
  additionalClasses?: string;
  onBack?: () => void;
}) => {
  const location = useLocation();
  const isCancelingEvent = useMemo(() => {
    const lastRoute = location.pathname.split("/").filter(Boolean).pop();
    return lastRoute === "cancel";
  }, [location]);
  const { date } = useScheduler();

  const handleBack = () => {
    onBack?.();
  };

  return (
    <div
      className={`flex gap-2 items-center w-full relative justify-center ${additionalClasses}`}
    >
      {hasGoBackButton ? (
        <button onClick={handleBack}>
          <ArrowLeftCircleIcon width={30} height={30} color={"#6662FF"} />
        </button>
      ) : isCancelingEvent ? (
        <XCircleIcon color="#6662FF" height={24} width={24} />
      ) : (
        <CheckCircleIcon color="#6662FF" height={24} width={24} />
      )}

      {date && (
        <span
          className={`font-medium text-secondary-text tracking-[0.3px] ${
            isCancelingEvent && "line-through"
          }`}
        >
          {formatDateWithTimeRange(date)}
        </span>
      )}

    </div>
  );
};

export default TimeScheduledDisplay;
